<template>
  <v-app id="inspire">
    <div style="margin: 0 0 50px 0;">
      <title>เมนูการทำงาน</title>
      <img src="../assets/image/UI Member PHOTO/Banner-05.png" class="img-fluid" alt="Responsive image" style="width: 100%;" />
      <div style="width: 95%; margin:auto;">
        <div style="padding-bottom: 20px">
          <div style="display: flex; padding-top: 10px;">
            <div style="width: 30%; text-align: center;">
              <img :src="picprofile" class="img-fluid" alt="Responsive image"
                style="width: 40px; height: 40px; border-radius: 50%;" />
            </div>
            <div style="width: 70%;">
              <h1 style="font-size: 15px; margin: 0; color:#7155c6; ">{{ textShowL1 }}</h1>
              <h1 style="font-size: 15px; margin: 0 0; ">{{ textShowL2 }}</h1>
            </div>
          </div>
        </div>

        <v-card>
          <v-toolbar style="padding: 0px;">
            <v-tabs v-model="tapoperation">
              <v-tabs-slider style="background-color:#392193;"></v-tabs-slider>
              <v-tab :href="`#tab-1`" :style="listcsstab[0]" v-on:click="tabStatusofyou(1)">
                <p style="font-size:18px; margin: 0;">สถานะทุกสาขา</p>
              </v-tab>
              <v-tab :href="`#tab-2`" :style="listcsstab[1]" v-on:click="tabStatusofyou(2)">
                <p style="font-size:18px; margin: 0;">สถานะของคุณ</p>
              </v-tab>
            </v-tabs>
          </v-toolbar>

          <v-tabs-items v-model="tapoperation" style="padding-bottom: 10px; margin-bottom: 20px;">
            <!--  tab 1 -->
            <v-tab-item :value="`tab-1`">
              <div>
                <CCard style="width: 95%; margin: 5px auto 10px auto;">
                  <CCardBody style="padding-top: 20px; padding-bottom: 5px; background-color:#e5e5e5;">
                    <v-skeleton-loader style="height: 35px; margin-bottom: 10px;" v-if="loadingdata.branch" type="image"></v-skeleton-loader>
                    <!-- <CSelect v-else id="selectTab1" @change="onChangeAllBranch($event)" :options="branchWW" style="font-family: Kanit, sans-serif;" /> -->
                    <CSelect v-else id="selectTab1" :value="$store.state.profile.branch" @change="onChangeAllBranch($event)" :options="$store.state.profile.branchWW" style="font-family: Kanit, sans-serif"/>

                  </CCardBody>
                </CCard>

                <center>
                  <v-card style="width: 95%; justify-content: flex-end;">
                    <v-toolbar style="padding: 0px;">
                      <v-tabs v-model="tapoperation2">
                        <!-- <v-tabs-slider style="background-color:#392193;"></v-tabs-slider> -->
                        <v-tab v-on:click="cssTab2(1)" :href="`#tab-1`" :style="listcsstab2[0]">
                          <p style="margin: 0;">{{ title_washer }}</p>
                        </v-tab>
                        <v-tab v-on:click="cssTab2(2)" :href="`#tab-2`" :style="listcsstab2[1]">
                          <p style="margin: 0;">{{ title_dryer }}</p>
                        </v-tab>
                      </v-tabs>
                    </v-toolbar>

                    <v-tabs-items v-model="tapoperation2">
                      <v-tab-item :value="`tab-1`" style="padding-bottom: 5px;">
                        <v-skeleton-loader v-if="loadingdata.statusAll" style="width: 95%; height: 131px; margin: 5px;"
                          type="image"></v-skeleton-loader>
                        <CCard v-else v-for="item in items_washer" :key="item.id" style="width: 95%; margin: 5px;">
                          <CCardHeader style="background-color: #beddfa;">
                            <h1 style="font-size:12px; margin: 0 0 0 0;">เครื่องซัก หมายเลข (No.) {{ item.id }}</h1>
                          </CCardHeader>
                          <CCardBody>
                            <div style="display: flex;">
                              <div>
                                <template v-if="item.pic != 'http://teedev.online/null'">
                                  <img :src=item.pic class="img-fluid" alt="Responsive image" style="width: 70px;">
                                </template>
                                <template v-else>
                                  <img src="../assets/image/UI Member PHOTO/noimage.png" class="img-fluid" alt="Responsive image" style="width: 70px;">
                                </template>
                              </div>
                              <div style="margin: auto auto;">
                                <!-- <div class="d-flex justify-content-center"> -->
                                <div class="">
                                  <h1 style="font-size:14px; color:#298def">ขนาด {{ item.weight }}</h1>
                                  <!-- <h1 style="font-size:14px; color:#808080">&nbsp; | &nbsp;</h1> -->
                                  <h1 style="font-size:14px; color:#298def">ราคาเริ่มต้น {{ item.startprice }} บาท</h1>
                                </div>
                                <div class="d-flex justify-content-center">
                                  <div style="padding-right: 5px;">
                                    <img :src=item.iconpic style="width: 20px" />
                                  </div>
                                  <div>
                                    <h1 :style="item.css">{{ item.status }}</h1>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CCardBody>
                        </CCard>
                      </v-tab-item>

                      <v-tab-item :value="`tab-2`" style="padding-bottom: 5px;">
                        <v-skeleton-loader v-if="loadingdata.statusAll" style="width: 95%; height: 131px; margin: 5px;" type="image"></v-skeleton-loader>
                        <CCard v-for="item in items_dryer" :key="item.id" style="width: 95%; margin: 5px;">
                          <CCardHeader style="background-color: #fedac1;">
                            <h1 style="font-size:12px; margin: 0 0 0 0;">เครื่องอบ หมายเลข (No.) {{ item.id }}</h1>
                          </CCardHeader>
                          <CCardBody>
                            <div style="display: flex;">
                              <div>
                                <template v-if="item.pic != 'https://teedev.online/null'">
                                  <img :src=item.pic class="img-fluid" alt="Responsive image" style="width: 70px;">
                                </template>
                                <template v-else>
                                  <img src="../assets/image/UI Member PHOTO/noimage.png" class="img-fluid" alt="Responsive image" style="width: 70px;">
                                </template>
                              </div>
                              <div style="margin: auto auto;">
                                <!-- <div class="d-flex justify-content-center"> -->
                                <div class="">
                                  <h1 style="font-size:14px; color:#f5911e">ขนาด {{ item.weight }}</h1>
                                  <!-- <h1 style="font-size:14px; color:#808080">&nbsp; | &nbsp;</h1> -->
                                  <h1 style="font-size:14px; color:#f5911e">ราคาเริ่มต้น {{ item.startprice }} บาท</h1>
                                </div>

                                <div class="d-flex justify-content-center">
                                  <div style="padding-right: 5px;">
                                    <img :src=item.iconpic style="width: 20px" />
                                  </div>
                                  <div>
                                    <h1 :style="item.css">{{ item.status }}</h1>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </CCardBody>
                        </CCard>
                      </v-tab-item>
                    </v-tabs-items>
                  </v-card>
                </center>
              </div>
            </v-tab-item>

            <!-- tab 2 -->
            <v-tab-item :value="`tab-2`">
              <center>
                <CCard style="width: 95%; margin: 5px;">
                  <CCardBody style="background-color:#e5e5e5;">
                    <!-- <CSelect id="selectTab2" @change="onChangeMainBranch($event)" :options="$store.state.profile.branchWW" style="font-family: Kanit, sans-serif;" /> -->
                    <CSelect id="selectTab2"  :value="$store.state.profile.branch" @change="onChangeMainBranch($event)" :options="$store.state.profile.branchWW" style="font-family: Kanit, sans-serif" />
                    <div style="display: flex;">
                      <div style="margin: 0 auto;">
                        <h3 style="font-size:14px; margin: 0 0;">เครดิตคงเหลือ</h3>
                        <h3 style="font-size:10px; color:#7D7D7D; margin: 0 0 0 -17px;">Credit balance</h3>
                        <div style="display: flex;">
                          <div>
                            <img src="../assets/image/UI Member PHOTO/Member icon-04.png" class="img-fluid"
                              alt="Responsive image" style="width: 22px; padding-top: 5px; margin: 0 5px 0 0;">
                          </div>
                          <v-skeleton-loader v-if="loadingdata.credit"
                            style="width: 50px; height: 30px; margin-top: 5px;" type="image"></v-skeleton-loader>
                          <template v-else>
                            <h1 style="font-size:25px; font-weight: bold; padding-top: 6px; padding-left: 0px">{{
                                Number($store.state.profile.money).toLocaleString()
                            }}</h1>
                            <h1 style="font-size:12px; color:#7D7D7D; padding-top: 18px; padding-left: 3px;">บาท</h1>
                          </template>
                        </div>
                      </div>
                      <div style="margin: auto auto;">
                        <!-- 
                        <CButton color="secondary" style="margin: 0 10px;">เติมเครดิต</CButton>
                        <p style="font-size:10px; color:#7D7D7D; margin: 0;">เครดิตจะถูกบันทึก</p>
                        <p style="font-size:10px; color:#7D7D7D; margin: 0;">ในสาขาที่คุณเลือก</p>
                         -->
                      </div>
                    </div>
                  </CCardBody>
                </CCard>
              </center>
              <template v-if="items_status.length > 0">
                <center>
                  <p style="font-size:15px; margin: 15px;">สถานะเครื่องที่ใช้บริการ</p>
                </center>
                <v-skeleton-loader v-if="loadingdata.statusUser" style="width: 95%; height: 131px; margin: 0 auto;"
                  type="image"></v-skeleton-loader>

                <CCard v-else v-for="item in items_status" :key="item.id" style="width: 95%; margin: 0 auto;">
         
                  <div v-if="item.waterstatus != ''">
                    <CCardHeader style="padding: 2%; background-color: #beddfa;" class="text-center">
                      <h1 style="font-size:15px; margin: 5px;"> {{ item.devicetype }} หมายเลข (No.) {{ item.id }}</h1>
                    </CCardHeader>
                    <CCardBody>
                      <div style="display: flex;">
                        <div>
                          <img :src=item.pic class="img-fluid" alt="Responsive image" style="width: 70px;">
                        </div>
                        <div style="margin: auto auto;">
                          <div class="d-flex  w-100">
                            <h1 style="font-size:14px; color:#298def; margin: 0 5px 0 5px;">{{ item.weight }}</h1>
                            <h1 style="font-size:14px; color:#808080; margin: 0 5px 0 5px;">&nbsp; | &nbsp;</h1>

                            <h1 style="font-size:14px; color:#298def; margin: 0 5px 0 5px;" v-if="item.waterstatus != ''">
                              {{ item.waterstatus }}</h1>
                            <h1 style="font-size:14px; color:#298def; margin: 0 5px 0 5px;" v-else>อบผ้า</h1>

                            <h1 style="font-size:14px; color:#808080; margin: 0 5px 0 5px;">&nbsp; | &nbsp;</h1>
                            <h1 style="font-size:14px; color:#298def; margin: 0 5px 0 5px;">{{ item.price }} บาท</h1>
                          </div>
                        </div>
                      </div>
                    </CCardBody>
                  </div>

                  <div v-else>
                    <CCardHeader style="padding: 2%; background-color: #fedac1;" class="text-center">
                      <h1 style="font-size:15px; margin: 5px;"> {{ item.devicetype }} หมายเลข (No.) {{ item.id }}</h1>
                    </CCardHeader>
                    <CCardBody>
                      <div style="display: flex;">
                        <div>
                          <img :src=item.pic class="img-fluid" alt="Responsive image" style="width: 70px;">
                        </div>
                        <div style="margin: auto auto;">
                          <div class="d-flex  w-100">
                            <h1 style="font-size:14px; color:#f5911e; margin: 0 5px 0 5px;">{{ item.weight }}</h1>
                            <h1 style="font-size:14px; color:#808080; margin: 0 5px 0 5px;">&nbsp; | &nbsp;</h1>

                            <h1 style="font-size:14px; color:#f5911e; margin: 0 5px 0 5px;" v-if="item.waterstatus != ''">
                              {{ item.waterstatus }}</h1>
                            <h1 style="font-size:14px; color:#f5911e; margin: 0 5px 0 5px;" v-else>อบผ้า</h1>

                            <h1 style="font-size:14px; color:#808080; margin: 0 5px 0 5px;">&nbsp; | &nbsp;</h1>
                            <h1 style="font-size:14px; color:#f5911e; margin: 0 5px 0 5px;">{{ item.price }} บาท</h1>
                          </div>
                        </div>
                      </div>
                    </CCardBody>
                  </div>

                  <!-- <CCardHeader style="padding: 2%; background-color: #fedac1;" class="text-center">
                    <h1 style="font-size:15px; margin: 5px;"> {{ item.devicetype }} หมายเลข (No.) {{ item.id }}</h1>
                  </CCardHeader>
                  <CCardBody>
                    <div style="display: flex;">
                      <div>
                        <img :src=item.pic class="img-fluid" alt="Responsive image" style="width: 70px;">
                      </div>
                      <div style="margin: auto auto;">
                        <div class="d-flex  w-100">
                          <h1 style="font-size:14px; color:#f5911e; margin: 0 5px 0 5px;">{{ item.weight }}</h1>
                          <h1 style="font-size:14px; color:#808080; margin: 0 5px 0 5px;">&nbsp; | &nbsp;</h1>

                          <h1 style="font-size:14px; color:#f5911e; margin: 0 5px 0 5px;" v-if="item.waterstatus != ''">
                            {{ item.waterstatus }}</h1>
                          <h1 style="font-size:14px; color:#f5911e; margin: 0 5px 0 5px;" v-else>อบผ้า</h1>

                          <h1 style="font-size:14px; color:#808080; margin: 0 5px 0 5px;">&nbsp; | &nbsp;</h1>
                          <h1 style="font-size:14px; color:#f5911e; margin: 0 5px 0 5px;">{{ item.price }} บาท</h1>
                        </div>
                      </div>
                    </div>
                  </CCardBody> -->

                </CCard>
              </template>
              <CCard v-if="items_status.length < 1" style="width: 95%; margin: 0 auto">
                <CCardBody>
                  <center>
                    <h1 style="font-size: 12px; color: #8e8d8d; margin: 0">
                      ไม่มีเครื่องที่ใช้บริการในขณะนี้.
                    </h1>
                  </center>
                </CCardBody>
              </CCard>
            </v-tab-item>
          </v-tabs-items>
        </v-card>


        <v-dialog v-model="statuslogin.dialogpassword" persistent width="500">
          <v-card style="background-color:#000;">
            <center>
              <img src="../assets/image/UI Member PHOTO/Icon Popup-01.png" class="img-fluid" alt="Responsive image"
                style="width: 30%; padding-top: 20px; padding-bottom: 20px;" />
              <h1 style="color: #8350ff; font-size: 20px">ระบบรักษาความปลอดภัย</h1>
              <h1 style="color: #8350ff; font-size: 20px">ด้วยรหัส Pin</h1>
            </center>


            <CCard style="width: 85%; margin: auto;">
              <CCardBody style="background-color:#e5e5e5;">
                <center>
                  <div style="padding-bottom: 0px;">
                    <v-skeleton-loader v-if="loadingdata.login.pin1 && loadingdata.login.pin2" style="width: 100%; height: 100px;" type="image"></v-skeleton-loader>
                    <template v-else>
                      <template v-if="loadingdata.login.pin1 == false">
                        <h1 style="font-size: 15px">กรอกรหัส Pin 4 หลัก</h1>
                        <CInput type="password" v-model="statuslogin.pin1" placeholder="pin" aria-label="pin"
                          aria-describedby="basic-addon1" style="font-family: Kanit, sans-serif;"
                          oninput=" this.value = (this.value.length > 4) ? this.value.slice(0,4) : this.value; /^[0-9]+(.[0-9]{1,3})?$/.test(this.value) ? this.value : this.value = this.value.slice(0,-1);">
                        </CInput>
                      </template>
                      <template v-if="loadingdata.login.pin2 == false">
                        <h1 style="font-size: 15px">กรอกรหัส Pin 4 หลักอีกครั้ง</h1>
                        <CInput type="password" v-model="statuslogin.pin2" placeholder="pin" aria-label="pin"
                          aria-describedby="basic-addon1" style="font-family: Kanit, sans-serif;"
                          oninput=" this.value = (this.value.length > 4) ? this.value.slice(0,4) : this.value; /^[0-9]+(.[0-9]{1,3})?$/.test(this.value) ? this.value : this.value = this.value.slice(0,-1);">
                        </CInput>
                      </template>
                      <div style="display: flex; width: 100%;">
                        <p style="color:#FF0000; margin: 0 0 0 0; padding: 0; width: 210px;">{{ warningText.pin }}</p>
                        <template v-if="loadingdata.login.pin2">
                          <div style="width: 100%; display: flex; text-decoration: underline; justify-content:flex-end;" @click="DialogForgetPin()">
                            <p :style="dataForgetPin.css">ลืมรหัสผ่าน</p>
                            <p v-if="dataForgetPin.timeoutForgetPin != 0" :style="dataForgetPin.css">
                              ({{ dataForgetPin.timeoutForgetPin }})</p>
                          </div>
                        </template>
                      </div>
                    </template>
                  </div>
                </center>
                <template v-if="warningText.pin != ''">
                  <p style="color:#FF0000; margin: 0 0 0 0; padding: 0;">{{ warningText.pin }}</p>
                </template>
              </CCardBody>
            </CCard>


            <div style="display: flex; justify-content: center; padding-top: 20px; padding-bottom: 20px;">
              <CButton v-on:click="CancelDialog()"
                style="background-color:#FF5C57; color:#FFFFFF; margin: 0 10px 0 10px; width: 30%;">
                <p style="margin: 0 10px;">ยกเลิก</p>
              </CButton>
              <CButton v-on:click="CheckPin()"
                style="background-color:#1EC688; color:#FFFFFF; margin: 0 10px 0 10px; width: 30%;">
                <p style="margin: 0 10px;">ตกลง</p>
              </CButton>
            </div>
          </v-card>
        </v-dialog>

        <v-dialog v-model="dataForgetPin.dialog" width="500">
          <v-card style="background-color:#FFF;">
            <div style="padding: 20px;">
              <center>
                <h1 style="color: #000; font-size: 15px">ต้องการให้เราได้ส่งรหัส Pin ใหม่ไปที่เบอร์</h1>
                <h1 style="color: #000; font-size: 15px">{{ profile.numberphone }} ใช่หรือไม่ ?</h1>
                <CButton v-on:click="ForgotPassword()"
                  style="background-color:#1EC688; color:#FFFFFF; margin: 0 10px 0 10px; width: 30%;">
                  <p style="margin: 0 10px;">ตกลง</p>
                </CButton>
              </center>
            </div>
          </v-card>
        </v-dialog>
      </div>
    </div>
  </v-app>
</template>

<script>
import MainChartExample from "./charts/MainChartExample";
import WidgetsDropdown from "./widgets/WidgetsDropdown";
import WidgetsBrand from "./widgets/WidgetsBrand";
const axios = require("axios");
import config from '../config'
import { freeSet } from "@coreui/icons";

export default {
  name: "Dashboard",
  components: {
    MainChartExample,
    WidgetsDropdown,
    WidgetsBrand,
  },
  data() {
    return {

      regisModal: false,
      items_washer: [],
      items_dryer: [],
      items_status: [{ "id": "", "weight": "", "price": "", "waterstatus": "", "devicetype": "", "pic": "http://pandata.online/public/images/devices/WS_SPEEDQUEEN_10_5KG_ATEE9ASP455LW01.jpg" }],
      profile: "",
      nameUser: "คุณ วันเดอร์ ซักสะอาด",
      textShowL1: "",
      textShowL2: "",
      money: 0,
      cnt_washer: 0,
      cnt_dryer: 0,
      title_washer: "เครื่องซัก ",
      title_dryer: "เครื่องอบ ",
      tabs: null,
      q_tab: "",
      branch: "",
      branchMain: "",
      branchWW: "",
      sex: "",
      picprofile: require('@/assets/image/UI Member PHOTO/Member icon-02.png'),

      tapoperation: 'tab-1',
      csstabActive: "width: 100%; color:#392193;",
      csstabnonActive: "width: 100%; color:#8E8D8D;",
      listcsstab: [],

      tapoperation2: 'tab-1',
      csstabActive2: "width: 100%; color:#FFFFFF; background-color:#298def;",
      csstabnonActive2: "width: 100%; color:#000000; background-color:#939598;",
      listcsstab2: [],
      statuslogin: { "pin1": "", "pin2": "", "branch": "", "numberphone": "", "dialogpassword": false, "status": false },
      loadingdata: {
        'branch': true,
        'credit': true,
        'statusAll': true,
        'statusUser': true,
        'login': { pin1: true, pin2: true }
      },
      warningText: {
        "pin": ""
      },

      tab2value: null,
      dataForgetPin: { dialog: false, timeoutForgetPin: 0, css: "color:#000; margin: 0;" },
      newUrl: "",
    };
  },
  mounted() {
    this.regisModal = this.$store.state.popupregis
    var listkey = Object.keys(this.$store.state.profile)
    if (listkey.length <= 3) {
      this.$router.push('/dashboard?pageww=operation')
    }

    this.q_tab = this.$route.query.tab
    if (this.q_tab == "statusall") {
      this.tapoperation = 'tab-1'
    }
    if (this.q_tab == "statusofyou") {
      this.tapoperation = 'tab-2'
      this.tabStatusofyou(2)
    }

    this.profile = this.$store.state.profile
    // this.$store.state.popupregis = this.$store.state.popupregis_buff

    this.nameUser = this.$store.state.profile.nemeuser
    this.sex = this.$store.state.profile.sex
    this.branch = this.$store.state.profile.branch
    this.selectProfile(this.sex)
    this.picprofile = require('@/assets/image/UI Member PHOTO/Member icon-02.png')
    this.textShowL1 = "Wonder Wash"
    this.textShowL2 = "ยินดีให้บริการ"
    this.listcsstab = [this.csstabActive, this.csstabnonActive]
    this.listcsstab2 = [this.csstabActive2, this.csstabnonActive2]

    this.API_device_status(this.branch).then((res) => {
      this.items_washer = res.data.washer
      this.items_dryer = res.data.dryer
      this.items_data = res.data

      // var url = window.location.href
      var url = "https://line.pandata.online/";
      let domain = (new URL(url));
      domain = domain.hostname;
      // console.log("url", domain)

      if (domain == "line.teedev.online" || domain == "line.pandata.online") {
        this.newUrl = domain.replace("line.", "https://");
        // console.log("image:", this.newUrl)
      } else {
        console.log("=====");
      }

      for (var i = 0; i < this.items_washer.length; i++) {
        this.items_washer[i].pic = this.newUrl + this.items_washer[i].pic
      }

      for (var i = 0; i < this.items_dryer.length; i++) {
        this.items_dryer[i].pic = this.newUrl + this.items_dryer[i].pic
      }
      console.log("this.items_washer ", this.items_washer)
      console.log("this.items_dryer ", this.items_dryer)


      for (var i = 0; i < this.items_washer.length; i++) {
        var r = this.GetStatus(this.items_washer[i].status)
        this.items_washer[i].status = r.status
        this.items_washer[i].iconpic = r.iconpic
        this.items_washer[i].css = r.css
      }
      for (var i = 0; i < this.items_dryer.length; i++) {
        var r = this.GetStatus(this.items_dryer[i].status)
        this.items_dryer[i].status = r.status
        this.items_dryer[i].iconpic = r.iconpic
        this.items_dryer[i].css = r.css
      }
      this.loadingdata.statusAll = false
      this.CntReady()
    });

    this.API_memberCredit(this.profile.numberphone).then((res) => {
      var listcredits = res.data
      var list_branch = []
      for (var i = 0; i < listcredits.length; i++) {
        list_branch.push(parseInt(listcredits[i].machineId))
        if (listcredits[i].machineId == this.branch) {
          this.$store.state.profile.money = listcredits[i].value
        }
      }
      if (!list_branch.includes(parseInt(this.branch))) {
        this.$store.state.profile.money = 0
      }
      this.loadingdata.credit = false
    });

    this.loadingdata.branch = true
    this.API_machinelist().then((res) => {
      for (var i = 0; i < res.data.length; i++) {
        res.data[i].label = res.data[i].value + res.data[i].label
        res.data[i].label = res.data[i].label.replace("Wonder Wash", " ");
        // console.log("data", res.data[i].label)
      }

      this.$store.state.profile.branchWW = res.data
      this.$store.state.profile.branchWW_org = res.data
      var data2 = this.$store.state.profile.branchWW
      for (var i = 0; i < data2.length; i++) {
        if (parseInt(data2[i].value) == parseInt(this.$store.state.profile.branch)) {
          data2.unshift(data2[i])
          data2.splice(i + 1, 1);
        }
      }
      this.branchWW = [...data2]
      this.loadingdata.branch = false
      // let tab2 = document.getElementById('selectTab2')
      // this.tab2value = tab2.value
    });

  },
  methods: {
    tabStatusofyou(e) {
      if (e == 2) {
        this.selectProfile(this.sex)
        this.loadingdata.statusUser = true
        // console.log("this.profile.numberphone", this.profile.numberphone, this.branchMain)
        // console.log("this.branchMai", this.branchMain)

        this.API_device_statusUser(this.profile.numberphone, this.branch).then((res) => {
          console.log("_API_device_statusUser",  this.items_status )
          this.items_status = res.data

          // var url = window.location.href
          var url = "https://line.pandata.online/";
          let domain = (new URL(url));
          domain = domain.hostname;
          // console.log("url", domain)

          if (domain == "line.teedev.online" || domain == "line.pandata.online") {
            // console.log(domain)
            this.newUrl = domain.replace("line.", "https://");
            // console.log(this.newUrl)
          } else {
            console.log("=====");
          }

          for (var i = 0; i < this.items_washer.length; i++) {
            this.items_status[i].pic = this.newUrl + this.items_status[i].pic
          }

          console.log("this.items_status ", this.items_status)

          this.loadingdata.statusUser = false


        });
      }
      else if (e == 1) {
        this.picprofile = require('@/assets/image/UI Member PHOTO/Member icon-02.png')
        this.textShowL1 = "Wonder Wash"
        this.textShowL2 = "ยินดีให้บริการ"

        var data = [...this.$store.state.profile.branchWW_org]

        for (var i = 0; i < data.length; i++) {
          if (parseInt(data[i].value) == parseInt(this.$store.state.profile.branch)) {
            data.unshift(data[i])
            data.splice(i + 1, 1);
          }
        }
        this.$store.state.profile.branchWW = [...data]
      }
      this.cssTab(e)
    },

    cssTab(numtab) {
      for (var i = 1; i <= this.listcsstab.length; i++) {
        if (i == numtab) {
          this.listcsstab[i - 1] = this.csstabActive
        }
        else {
          this.listcsstab[i - 1] = this.csstabnonActive
        }
      }
    },

    cssTab2(numtab) {
      if (numtab == 1) {
        this.listcsstab2[numtab - 1] = "width: 100%; color:#FFFFFF; background-color:#298def;"
        this.listcsstab2[numtab] = "width: 100%; color:#000000; background-color:#939598;"
      }

      if (numtab == 2) {
        this.listcsstab2[numtab - 1] = "width: 100%; color:#000000; background-color:#f5911e;"
        this.listcsstab2[numtab - 2] = "width: 100%; color:#000000; background-color:#939598;"
      }
    },

    selectProfile(sex) {
      if (sex == "male") {
        this.picprofile = this.profile.pictureUrl
        this.textShowL1 = "สวัสดีครับ ,"
        this.textShowL2 = "คุณ " + this.nameUser
      }
      else if (sex == "female") {
        this.picprofile = this.profile.pictureUrl
        this.textShowL1 = "สวัสดีค่ะ ,"
        this.textShowL2 = "คุณ " + this.nameUser
      }
      else if (sex == "other") {
        this.picprofile = this.profile.pictureUrl
        this.textShowL1 = "สวัสดีค่ะ ,"
        this.textShowL2 = "คุณ " + this.nameUser
      }
    },

    // tag 1 
    onChangeAllBranch(e) {
      // console.log(e)
      const { value } = e.target
      this.$store.state.profile.branch = value
  
      setTimeout(() => {
        this.SortListBranchWW().then((data) => {
          var tab1 = document.getElementById("selectTab1");
          tab1.value = value
          // console.log("tab1", tab1)
          // console.log("value", value)
        });
      });

      this.branch = parseInt(value)
      console.log("tag 1 branc:", value)

      this.loadingdata.credit = true
      this.loadingdata.statusAll = true

      this.statuslogin.status = false

      this.API_memberCredit(this.profile.numberphone, this.branch).then((res) => {
        // console.log("API_memberCredit ",res.data)
        var listcredits = res.data
        var list_branch = []
        for (var i = 0; i < listcredits.length; i++) {
          list_branch.push(parseInt(listcredits[i].machineId))
          if (listcredits[i].machineId == this.branch) {
            this.$store.state.profile.money = listcredits[i].value
          }
        }
        if (!list_branch.includes(this.branch)) {
          this.$store.state.profile.money = 0
        }
        this.loadingdata.credit = false
      });

      this.API_device_status(this.branch).then((res) => {
        console.log('this.branch', this.branch)
        this.items_washer = res.data.washer
        this.items_dryer = res.data.dryer
        this.items_data = res.data

        // var url = window.location.href
        var url = "https://line.pandata.online/";
        let domain = (new URL(url));
        domain = domain.hostname;
        // console.log("url", domain)

        if (domain == "line.teedev.online" || domain == "line.pandata.online") {
          // console.log(domain)
          this.newUrl = domain.replace("line.", "https://");
          // console.log(this.newUrl)
        } else {
          console.log("=====");
        }

        for (var i = 0; i < this.items_washer.length; i++) {
          this.items_washer[i].pic = this.newUrl + this.items_washer[i].pic
        }

        for (var i = 0; i < this.items_dryer.length; i++) {
          this.items_dryer[i].pic = this.newUrl + this.items_dryer[i].pic
        }

        console.log("this.items_washer ", this.items_washer)
        console.log("this.items_dryer ", this.items_dryer)

        for (var i = 0; i < this.items_washer.length; i++) {
          var r = this.GetStatus(this.items_washer[i].status)
          this.items_washer[i].status = r.status
          this.items_washer[i].iconpic = r.iconpic
          this.items_washer[i].css = r.css
        }
        this.items_washer = [...this.items_washer]
        for (var i = 0; i < this.items_dryer.length; i++) {
          var r = this.GetStatus(this.items_dryer[i].status)
          this.items_dryer[i].status = r.status
          this.items_dryer[i].iconpic = r.iconpic
          this.items_dryer[i].css = r.css
        }
        this.items_dryer = [...this.items_dryer]
        this.CntReady()
        this.loadingdata.statusAll = false
      });
    },

    //  tag 2
    onChangeMainBranch(e) {
      const { value } = e.target
      this.branch = value
      this.statuslogin.pin1 = ''
      this.statuslogin.pin2 = ''
      this.warningText.pin = ""

      console.log("tag 2 branc:", value)

      if (this.$store.state.profile.setting.AskPinLine == "1") {
        this.statuslogin.dialogpassword = true
      }
      this.loadingdata.login = { pin1: true, pin2: true }

      this.API_Checkmemberstatus(
        this.$store.state.profile.numberphone, 
        value, 
        this.$store.state.profile.userId
      )
      .then((res) => {
        if (res.data.password) {
          this.loadingdata.login = { pin1: false, pin2: true }
          if (this.$store.state.profile.setting.AskPinLine != "1") {
            this.$store.state.profile.branch = value
            let dataBranch = {
              'userid': this.$store.state.profile.userId,
              "numberphone": this.$store.state.profile.numberphone,
              "branch": this.$store.state.profile.branch,
              "pin": this.statuslogin.pin1
            }
            this.API_ChangeMainBranch(dataBranch).then((res) => {
              if (res.data.success) {
                this.LoadDataOfBranch()
                this.CancelDialog()
              }
            });
          }
        }
        else {
          this.statuslogin.dialogpassword = true
          this.loadingdata.login = { pin1: false, pin2: false }
        }
      })
    },

    CancelDialog() {
      this.statuslogin.dialogpassword = false;
      this.statuslogin.pin1 = ''
      this.statuslogin.pin2 = ''

      let tab2 = document.getElementById('selectTab2')
      tab2.value = this.$store.state.profile.branch

      let tab1 = document.getElementById('selectTab1')
      tab1.value = this.$store.state.profile.branch
    },

    CntReady() {
      this.cnt_washer = 0
      for (var i = 0; i < this.items_washer.length; i++) {
        if (this.items_washer[i].status == "พร้อมใช้งาน") {
          this.cnt_washer = this.cnt_washer + 1
        }
      }
      this.cnt_dryer = 0
      for (var i = 0; i < this.items_dryer.length; i++) {
        if (this.items_dryer[i].status == "พร้อมใช้งาน") {
          this.cnt_dryer = this.cnt_dryer + 1
        }
      }
      this.title_washer = "เครื่องซัก (" + JSON.stringify(this.cnt_washer) + "/" + JSON.stringify(this.items_washer.length) + ")"
      this.title_dryer = "เครื่องอบ (" + JSON.stringify(this.cnt_dryer) + "/" + JSON.stringify(this.items_dryer.length) + ")"
    },

    CheckPin() {
      if (this.statuslogin.pin1.length == 4 && this.loadingdata.login.pin1 == false && this.loadingdata.login.pin2) {
        let data = { "pin": this.statuslogin.pin1, "numberphone": this.$store.state.profile.numberphone, "branch": parseInt(this.branch) }
        this.API_Login(data).then((res) => {
          this.statuslogin.status = res.data.comparepin
          this.statuslogin.dialogpassword = !res.data.comparepin

          if (res.data.comparepin) {
            this.warningText.pin = ""
            let tab2 = document.getElementById('selectTab2')
            this.$store.state.profile.branch = tab2.value

            let dataBranch = {
              'userid': this.$store.state.profile.userId,
              "numberphone": this.$store.state.profile.numberphone,
              "branch": this.$store.state.profile.branch,
              "pin": this.statuslogin.pin1
            }
            this.API_ChangeMainBranch(dataBranch).then((res) => {
              if (res.data.success) {
                this.LoadDataOfBranch()
                this.CancelDialog()
              }
            });
          }
          else {
            this.warningText.pin = "*รหัสผ่านไม่ถูกต้อง"
          }
        });
      }
      if (this.statuslogin.pin1 == this.statuslogin.pin2 && this.statuslogin.pin1.length == 4 && this.loadingdata.login.pin1 == false && this.loadingdata.login.pin2 == false) {
        let data = {
          "numberphone": this.$store.state.profile.numberphone,
          "rqPasswordKiosk": true,
          "pin": this.statuslogin.pin1,
          "branch": this.$store.state.profile.branch,
        }
        this.API_Createuser(data).then((res) => {
          if (res.data.status) {
            let tab2 = document.getElementById('selectTab2')
            this.$store.state.profile.branch = tab2.value

            let dataBranch = {
              'userid': this.$store.state.profile.userId,
              "numberphone": this.$store.state.profile.numberphone,
              "branch": this.$store.state.profile.branch,
              "pin": this.statuslogin.pin1
            }
            this.API_ChangeMainBranch(dataBranch).then((res) => {
              if (res.data.success) {
                this.LoadDataOfBranch()
                this.CancelDialog()
              }
            });
          }
        })
      }
      if (this.statuslogin.pin1 == this.statuslogin.pin2 && this.statuslogin.pin1.length > 4 && this.loadingdata.login.pin2 == false) {
        this.warningText.pin = "*กำหนด Pin ด้วยตัวเลข 4 หลัก"
      }
      if (this.statuslogin.pin1 != this.statuslogin.pin2 && this.loadingdata.login.pin2 == false) {
        this.warningText.pin = "*กรอก pin ให้ตรงกัน"
      }
    },

    // onChangeBranch(e) {
    //   const { value } = e.target
    //   this.branch = value
    //   if (this.$store.state.profile.setting.AskPinLine == "1") {
    //     this.statuslogin.dialogpassword = true
    //   }
    //   this.loadingdata.login = { pin1: true, pin2: true }
    //   this.warningText.pin = ""

    //   this.API_Checkmemberstatus(this.$store.state.profile.numberphone, value, this.$store.state.profile.userId).then((res) => {
    //     if (res.data.password) {
    //       this.loadingdata.login = { pin1: false, pin2: true }
    //       if (this.$store.state.profile.setting.AskPinLine != "1") {
    //         let tab1 = document.getElementById('selectTab2')
    //         this.$store.state.profile.branch = tab1.value
    //         let dataBranch = {
    //           'userid': this.$store.state.profile.userId,
    //           "numberphone": this.$store.state.profile.numberphone,
    //           "branch": this.$store.state.profile.branch,
    //           "pin": this.statuslogin.pin1
    //         }
    //         this.API_ChangeMainBranch(dataBranch).then((res) => {
    //           if (res.data.success) {
    //             this.LoadDataOfBranch()
    //             this.CancelDialog()
    //           }
    //         });
    //       }
    //     }
    //     else {
    //       this.statuslogin.dialogpassword = true
    //       this.loadingdata.login = { pin1: false, pin2: false }
    //       this.warningText.pin = ""
    //     }
    //   })


    // },

    CancelDialog() {
      this.statuslogin.dialogpassword = false;
      this.statuslogin.pin1 = ''
      this.statuslogin.pin2 = ''

      let tab1 = document.getElementById('selectTab2')
      tab1.value = this.$store.state.profile.branch
    },

    LoadDataOfBranch() {
      this.loadingdata.credit = true
      this.loadingdata.statusAll = true
      this.loadingdata.statusUser = true

      this.SortListBranchWW().then((data) => {
        var tab2 = document.getElementById('selectTab2')
        tab2.value = this.$store.state.profile.branch
      })

      this.API_memberCredit(this.$store.state.profile.numberphone).then((res) => {
        var listcredits = res.data
        var list_branch = []
        for (var i = 0; i < listcredits.length; i++) {
          list_branch.push(parseInt(listcredits[i].machineId))
          if (listcredits[i].machineId == this.$store.state.profile.branch) {
            this.$store.state.profile.money = listcredits[i].value
          }
        }
        if (!list_branch.includes(parseInt(this.$store.state.profile.branch))) {
          this.$store.state.profile.money = 0
        }
        this.loadingdata.credit = false
      });

      this.API_device_statusUser(this.profile.numberphone, this.branch).then((res) => {
        this.items_status = res.data

        // var url = window.location.href
        var url = "https://line.pandata.online/";
        let domain = (new URL(url));
        domain = domain.hostname;
        // console.log("url", domain)

        if (domain == "line.teedev.online" || domain == "line.pandata.online") {
          // console.log(domain)
          this.newUrl = domain.replace("line.", "https://");
          // console.log(this.newUrl)
        } else {
          console.log("=====");
        }

        for (var i = 0; i < this.items_washer.length; i++) {
          this.items_status[i].pic = this.newUrl + this.items_status[i].pic
        }

        console.log("this.items_status ", this.items_status)
        this.loadingdata.statusUser = false

      });

      this.API_device_status(this.branch).then((res) => {
        this.items_washer = res.data.washer
        this.items_dryer = res.data.dryer
        for (var i = 0; i < this.items_washer.length; i++) {
          var r = this.GetStatus(this.items_washer[i].status)
          this.items_washer[i].status = r.status
          this.items_washer[i].iconpic = r.iconpic
          this.items_washer[i].css = r.css
        }
        for (var i = 0; i < this.items_dryer.length; i++) {
          var r = this.GetStatus(this.items_dryer[i].status)
          this.items_dryer[i].status = r.status
          this.items_dryer[i].iconpic = r.iconpic
          this.items_dryer[i].css = r.css
        }

        this.CntReady()
        this.loadingdata.statusAll = false
      });

    },

    SortListBranchWW() {
      return new Promise((resolve, reject) => {
        let data = [...this.$store.state.profile.branchWW_org]
        for (var i = 0; i < data.length; i++) {
          if (parseInt(data[i].value) == parseInt(this.$store.state.profile.branch)) {
            data.unshift(data[i])
            data.splice(i + 1, 1);
          }
        }
        this.$store.state.profile.branchWW = [...data]
        resolve(data)
      })
    },

    StatusDetail(color) {
      var iconpic
      var css
      if (color == 'gray') {
        iconpic = require('@/assets/image/UI Member PHOTO/clock-gray.png')
        css = "font-size:14px; margin: 5px 0 0 0; color:#8C8C8C"
      }
      else if (color == 'green') {
        iconpic = require('@/assets/image/UI Member PHOTO/clock-green.png')
        css = "font-size:14px; margin: 5px 0 0 0; color:#1EC688"
      }
      else if (color == 'blue') {
        iconpic = require('@/assets/image/UI Member PHOTO/clock-blue.png')
        css = "font-size:14px; margin: 5px 0 0 0; color:#298DEF"
      }
      return { "iconpic": iconpic, "css": css }
    },

    GetStatus(st) {
      var status
      var iconpic
      var css

      var res
      if (st == "UNKNOW") {
        status = "ไม่ระบุ"
        res = this.StatusDetail("gray")
        iconpic = res.iconpic
        css = res.css
      }
      else if (st == "BUSY") {
        status = "ไม่ว่าง"
        res = this.StatusDetail("blue")
        iconpic = res.iconpic
        css = res.css
      }
      else if (st == "READY") {
        status = "พร้อมใช้งาน"
        res = this.StatusDetail("green")
        iconpic = res.iconpic
        css = res.css
      }
      else if (st == "WASHING") {
        status = "กำลังทำงาน"
        res = this.StatusDetail("blue")
        iconpic = res.iconpic
        css = res.css
      }
      else if (st == "MC_FAILED") {
        status = "เสีย"
        res = this.StatusDetail("gray")
        iconpic = res.iconpic
        css = res.css
      }
      else if (st == "COMMU_FAILED") {
        status = "การสื่อสารขัดข้อง"
        res = this.StatusDetail("gray")
        iconpic = res.iconpic
        css = res.css
      }
      else if (st == "MC_NOT_SUPPORT") {
        status = "โมเดลเครื่องไม่รองรับ"
        res = this.StatusDetail("gray")
        iconpic = res.iconpic
        css = res.css
      }
      else if (st == "BUSY_POWERUP") {
        status = "รอเปิดเครื่อง"
        res = this.StatusDetail("blue")
        iconpic = res.iconpic
        css = res.css
      }
      else if (st == "BUSY_WAIT_CLOSING_DOOR") {
        status = "รอปิดประตูเครื่องเพื่อเช็คระบบ"
        res = this.StatusDetail("blue")
        iconpic = res.iconpic
        css = res.css
      }
      else if (st == "BUSY_DOOR_OPEN") {
        status = "ประตูเครื่องเปิด"
        res = this.StatusDetail("blue")
        iconpic = res.iconpic
        css = res.css
      }
      else if (st == "BUSY_WAIT_USER") {
        status = "รอกดปุ่มเริ่มทำงาน"
        res = this.StatusDetail("blue")
        iconpic = res.iconpic
        css = res.css
      }
      else if (st == "BUSY_WASH") {
        status = "กำลังซัก"
        res = this.StatusDetail("blue")
        iconpic = res.iconpic
        css = res.css
      }
      else if (st == "BUSY_RINSE") {
        status = "กำลังล้างน้ำ"
        res = this.StatusDetail("blue")
        iconpic = res.iconpic
        css = res.css
      }
      else if (st == "BUSY_SPIN") {
        status = "กำลังปั่นหมาด"
        res = this.StatusDetail("blue")
        iconpic = res.iconpic
        css = res.css
      }
      else if (st == "BUSY_DRYING") {
        status = "กำลังอบ"
        res = this.StatusDetail("blue")
        iconpic = res.iconpic
        css = res.css
      }
      else if (st == "BUSY_COOL_DOWN") {
        status = "ลดความร้อน"
        res = this.StatusDetail("blue")
        iconpic = res.iconpic
        css = res.css
      }
      else if (st == "BUSY_DRYER_EXTENSION") {
        status = "กำลังอบ"
        res = this.StatusDetail("blue")
        iconpic = res.iconpic
        css = res.css
      }
      else if (st == "CLOSE_SERVICE") {
        status = "ปิดบริการ"
        res = this.StatusDetail("gray")
        iconpic = res.iconpic
        css = res.css
      }
      else if (st == "ERROR") {
        status = "ไม่พร้อมใช้งาน"
        res = this.StatusDetail("gray")
        iconpic = res.iconpic
        css = res.css
      }
      else {
        status = st
        res = this.StatusDetail("gray")
        iconpic = res.iconpic
        css = res.css
      }


      return { "status": status, "iconpic": iconpic, "css": css }
    },

    DialogForgetPin() {
      if (this.dataForgetPin.timeoutForgetPin == 0) {
        this.dataForgetPin.dialog = true
      }
    },

    ForgotPassword() {
      this.dataForgetPin.dialog = false
      if (this.dataForgetPin.timeoutForgetPin == 0) {
        this.dataForgetPin.timeoutForgetPin = 180
        this.dataForgetPin.css = "color:#5f5e5e; margin: 0;"
        var timer = setInterval(() => {
          if (this.dataForgetPin.timeoutForgetPin != 0) {
            --this.dataForgetPin.timeoutForgetPin
          }
          else {
            clearInterval(timer)
            this.dataForgetPin.css = "color:#5f5e5e; margin: 0;"
          }
        }, 1000);
        let data = {
          "userId": this.profile.userId,
          "numberphone": this.profile.numberphone,
          "branch": this.branch
        }
        this.API_ForgetPin(data).then((res) => { })
      }
    },

    API_ForgetPin(data) {
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios.post(config.server + "/forgetpin", data, {
          headers: header,
        })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },

    API_ChangeMainBranch(data) {
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios
          .post(config.server + "/changemainbranch", data, {
            headers: header,
          })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },

    API_nameuser(data) {
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios
          .post(config.server + "/nameuser", data, {
            headers: header,
          })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },
    
    API_machinelist() {
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios.get(config.server + "/machinelist", {
          headers: header,
        })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },

    API_device_status(data) {
      console.log('API_device_status', data)
      console.log('this.$store.state.profile.branch',  this.$store.state.profile.branch)
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios.get(config.server + "/status?machineId=" + data, {
          headers: header,
        })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },

    API_memberCredit(refNumber, machineId) {
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios.get(config.server + "/credits?refNumber=" + refNumber + "&machineId=" + machineId, {
          headers: header,
        })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },

    API_device_statusUser(refNumber, machineId) {
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios.get(config.server + "/current?refNumber=" + refNumber + "&machineId=" + machineId, {
          headers: header,
        })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },

    API_Login(data) {
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios
          .post(config.server + "/login", data, {
            headers: header,
          })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },

    API_Checkmemberstatus(refNumber, machineId, datauserid) {
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios.get(config.server + '/checkmemberstatus/?refNumber=' + refNumber + '&machineId=' + machineId + '&userid=' + datauserid, {
          headers: header,
        })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },

    API_Createuser(data) {
      // console.log(data)
      return new Promise((resolve, reject) => {
        const header = { "Content-Type": "application/json" };
        axios.post(config.server + "/memberpin", data, {
          headers: header,
        })
          .then(function (res) {
            resolve(res);
          })
          .catch(function (err) {
            reject(err);
          });
      });
    },

  },
};
</script>



